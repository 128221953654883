import React from 'react'
import Radium from 'radium'
import ReactGA from 'react-ga'

import { padding, fontFamily, fontSize, color } from './CommonStyles'
import CustomButton from './CustomButton.js'
import findIndex from 'lodash/findIndex'

const styles={
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        // backgroundColor: color.deepBlue,
        //paddingLeft: padding.extraSmall,
        paddingTop: padding.small,
        paddingBottom: padding.small
        // marginTop: padding.small,
        // marginBottom: padding.verySmall
    },

    innerContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    }, 

    button: {
        fontFamily: fontFamily.din,
        fontSize: fontSize.small,
        textTransform: 'uppercase',
        paddingLeft: padding.tinySmall,
        paddingRight: padding.tinySmall,
        paddingBottom: padding.extraSmall,
        color: color.deepBlue,
        backgroundColor: 'transparent',
        // boxShadow: boxShadow.alphaDeepBlueSoft,
        border: 'inherit',
        // marginLeft: padding.extraSmall,
        marginRight: padding.verySmall,
        opacity: '95%',
        letterSpacing: '2.0px',

        '@media (min-width: 450px)': {  
            // No change. 
        },

        '@media (min-width: 600px)': {  
            // No change.
        },

        '@media (min-width: 750px)': {  
            //fontSize: fontSize.small
        },

        '@media (min-width: 900px)': {
            // no change.
            fontSize: fontSize.big
        }, 

        '@media (min-width: 1200px)' : {
            // fontSize: fontSize.big,
        },

        '@media (min-width: 1400px)' : {
           // no change. 
    
        },

        '@media (min-width: 1700px)' : {
            // fontSize: fontSize.extraBig
        }
    },
};

class CVFilterer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEducation: false,
            isExhibitions: false,
            isResidencies: false,
            isPublicTalks: false,
            isPress: false,
            isPublications: false,
            isAwards: false,
            isSkills: false,
            isWorkExperience: false
        }; 

        // Maintain a list of selected keywords. 
        this.categories = [];  
    }

    render() {
        return (
            <div style={styles.container}>
                <div style={styles.innerContainer}>
                <CustomButton isActive={this.state.isEducation} buttonStyle={styles.button} onClick={this.onCategorySelected.bind(this)}>{'Education'}</CustomButton>
                    {/* <CustomButton isActive={this.state.isWorkExperience} buttonStyle={styles.button} onClick={this.onCategorySelected.bind(this)}>{'Experience'}</CustomButton> */}
                    {/* <CustomButton isActive={this.state.isSkills} buttonStyle={styles.button} onClick={this.onCategorySelected.bind(this)}>{'Skills'}</CustomButton> */}
                    <CustomButton isActive={this.state.isExhibitions} buttonStyle={styles.button} onClick={this.onCategorySelected.bind(this)}>{'Exhibitions'}</CustomButton>
                    <CustomButton isActive={this.state.isResidencies} buttonStyle={styles.button} onClick={this.onCategorySelected.bind(this)}>{'Residencies'}</CustomButton>
                    <CustomButton isActive={this.state.isAwards} buttonStyle={styles.button} onClick={this.onCategorySelected.bind(this)}>{'Awards'}</CustomButton>
                    <CustomButton isActive={this.state.isPublicTalks} buttonStyle={styles.button} onClick={this.onCategorySelected.bind(this)}>{'Public Talks'}</CustomButton>
                    <CustomButton isActive={this.state.isPublications} buttonStyle={styles.button} onClick={this.onCategorySelected.bind(this)}>{'Publications'}</CustomButton>
                    {/* <CustomButton isActive={this.state.isPress} buttonStyle={styles.button} onClick={this.onCategorySelected.bind(this)}>{'Press'}</CustomButton> */}
                </div>
            </div>
        );
    }

    onCategorySelected(category) {
        this.setCategoryState(category); 

        var i = findIndex(this.categories, k => {
            return k === category;
        }); 

        if (i >= 0) {
            // Splice that array
            this.categories.splice(i, 1); 
        } else {
            this.categories.push(category); 
        }

        ReactGA.event({
            category: 'Filter: CV',
            action: 'Button click',
            label: category
        }); 


        // Send year and categories. 
        this.props.onKeywordSelected(this.categories); 
    }

    setCategoryState(category) {
        switch(category) {
            case 'Education': {
                this.setState({
                    isEducation: !this.state.isEducation
                }); 
                break; 
            }

            case 'Exhibitions': {
                this.setState({
                    isExhibitions: !this.state.isExhibitions
                }); 
                break; 
            }

            case 'Residencies': {
                this.setState({
                    isResidencies: !this.state.isResidencies
                }); 
                break; 
            }

            case 'Public Talks': {
                this.setState({
                    isPublicTalks: !this.state.isPublicTalks
                }); 
                break; 
            }

            case 'Press': {
                this.setState({
                    isPress: !this.state.isPress
                }); 
                break; 
            }

            case 'Publications': {
                this.setState({
                    isPublications: !this.state.isPublications
                }); 
                break; 
            }

            case 'Awards': {
                this.setState({
                    isAwards: !this.state.isAwards
                }); 
                break; 
            }

            case 'Skills': {
                this.setState({
                    isSkills: !this.state.isSkills
                }); 
                break; 
            }

            case 'Experience': {
                this.setState({
                    isWorkExperience: !this.state.isWorkExperience
                }); 
                break; 
            }

            default: {
                break;
            }
        }
    }
}

export default Radium(CVFilterer);